<template lang="pug">
    section#background
        img(src="@images/background/bg-experience.png").bg-experience
        img(src="@images/background/bg-inscrevase.png").bg-inscrevase
        img(src="@images/background/bg-galeria.png").bg-galeria
        img(src="@images/objetos/carbine.png").carbine
        img(src="@images/objetos/rope.png").rope
        img(src="@images/objetos/compass.png").compass
</template>

<script>
export default {
    name: "section-background",
}
</script>

<style lang="stylus" scoped src="./Background.styl"></style>